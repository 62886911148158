import React, { useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import agriculturalSeedGuideImage from "./assets/images/AgriculturalSeedGuide.png";
import environmentalGameGuideImage from "./assets/images/EnvironmentalGameGuide.png";

import seedAgriGuide from "./assets/pdfs/catalogues/SeedAgriGuide.pdf";
import environmentalGameGuide from "./assets/pdfs/catalogues/EnvironmentalGameGuide.pdf";

import Faith from "./assets/pdfs/maizevarieties/Faith.pdf";
import EsLovely from "./assets/pdfs/maizevarieties/Es Lovely.pdf";
import EsMyrdal from "./assets/pdfs/maizevarieties/Es Myrdal.pdf";
import EsConstance from "./assets/pdfs/maizevarieties/Es Constance.pdf";
import Bonnie from "./assets/pdfs/maizevarieties/Bonnie.pdf";
import EsLegolas from "./assets/pdfs/maizevarieties/Es Legolas.pdf";
import Emeleen from "./assets/pdfs/maizevarieties/Emeleen.pdf";
import Cathy from "./assets/pdfs/maizevarieties/Cathy.pdf";
import Crosbey from "./assets/pdfs/maizevarieties/Crosbey.pdf";
import Micheleen from "./assets/pdfs/maizevarieties/Micheleen.pdf";
import EsMetronom from "./assets/pdfs/maizevarieties/Es Metronom.pdf";
import Jakleen from "./assets/pdfs/maizevarieties/Jakleen.pdf";

const pdfs = [
  { name: "Faith", url: Faith },
  { name: "Es Lovely", url: EsLovely },
  { name: "Es Myrdal", url: EsMyrdal },
  { name: "Es Constance", url: EsConstance },
  { name: "Bonnie", url: Bonnie },
  { name: "Es Legolas", url: EsLegolas },
  { name: "Emeleen", url: Emeleen },
  { name: "Cathy", url: Cathy },
  { name: "Crosbey", url: Crosbey },
  { name: "Micheleen", url: Micheleen },
  { name: "Es Metronom", url: EsMetronom },
  { name: "Jakleen", url: Jakleen },
];

function Landing() {
  const [selectedPDFs, setSelectedPDFs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleSelection = (url) => {
    setSelectedPDFs((prev) =>
      prev.includes(url) ? prev.filter((pdf) => pdf !== url) : [...prev, url]
    );
  };

  const downloadSelected = () => {
    selectedPDFs.forEach((pdfUrl) => {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = pdfUrl.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    setIsModalOpen(false);
    setSelectedPDFs([]);
  };

  return (
    <div className="landing-container">
      <img src="/Grainseed.jpg" alt="Banner Logo" className="bannerlogo" />
      <div className="shift-up">
        <p>
          Our website is currently under construction. We have provided a
          limited view for now, however you can still contact us or download our
          PDFs in the meantime.
        </p>
        <button className="open-modal-btn" onClick={() => setIsModalOpen(true)}>
          View Maize Variety Guides
        </button>
        <div className="catalogue-images">
          <a
            href={seedAgriGuide}
            target="_blank"
            rel="noopener noreferrer"
            className="catalogue-image-link"
          >
            <img
              src={agriculturalSeedGuideImage}
              alt="Agricultural Seed Guide"
              className="catalogue-image"
            />
          </a>
          <a
            href={environmentalGameGuide}
            target="_blank"
            rel="noopener noreferrer"
            className="catalogue-image-link"
          >
            <img
              src={environmentalGameGuideImage}
              alt="Environmental Game Guide"
              className="catalogue-image"
            />
          </a>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h2>Select PDFs to Download</h2>
              <button className="close-modal-btn" onClick={() => setIsModalOpen(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <ul className="pdf-list">
              {pdfs.map((pdf) => (
                <li key={pdf.name}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={() => toggleSelection(pdf.url)}
                      checked={selectedPDFs.includes(pdf.url)}
                    />
                    {pdf.name}
                  </label>
                </li>
              ))}
            </ul>
            <button
              className="download-btn"
              onClick={downloadSelected}
              disabled={selectedPDFs.length === 0}
            >
              Download Selected PDFs
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Landing;
