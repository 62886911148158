import React, { useState } from 'react';
import './App.css';

function Careers() {
  const [showApply, setShowApply] = useState(false);

  const toggleApply = () => {
    setShowApply(!showApply);
  };

  return (
    <div className="careers-container">
      <h1>Careers at Grainseed</h1>
      <div className="careers-content">
        <div className="vacancy-card">
          <h2>Warehouse Supervisor</h2>
          <p><strong>Location:</strong> Grainseed – Eye</p>
          <p><strong>Reporting to:</strong> Lucy Hill</p>
          <p><strong>Hours:</strong> 40 hours per week</p>
          <p>
            The main purpose of the role is to manage the daily operations of our warehouse and seed processing plant. Working closely with the Logistics Coordinator & existing Senior Plant Operative, you will oversee all aspects of this area, including but not limited to:
          </p>
          <ul>
            <li>Responsibility for the preparation, distribution, and supervision of daily & weekly work schedules</li>
            <li>Supervision of the performance of our operatives</li>
            <li>Being the main liaison point between office logistics and warehouse production</li>
            <li>Management of all goods-in and goods-out processes</li>
            <li>Ensuring effective maintenance & safety checks are carried out and the appropriate documentation maintained, while making sure that all relevant Codes of Practice and Health & Safety regulations are complied with</li>
          </ul>
          <p>
            We are looking for someone with previous supervisory experience in warehouse operations and stock control, ideally gained in an agricultural industry, although this is not essential. A BASIS Storekeepers Certificate and forklift licence would also be highly desirable. This is a great opportunity for someone to join us and make the role their own.
          </p>
          <button className="apply-button" onClick={toggleApply}>
            {showApply ? 'Hide Details' : 'Apply'}
          </button>
          <div className={`apply-info ${showApply ? 'show' : ''}`}>
            <h3>Apply for this vacancy</h3>
            <p><strong>Contact:</strong> Laura Witterick</p>
            <p><strong>Email:</strong> <a href="mailto:laurawitterick@cofcointernational.com">laurawitterick@cofcointernational.com</a></p>
            <p><strong>Phone:</strong> <a href="tel:+441473275062">+44 1473 275 062</a></p>
            <p><strong>HR Correspondence Address:</strong></p>
            <address>
              COFCO International UK Ltd<br />
              15 The Havens<br />
              Ipswich, Suffolk, IP3 9SJ<br />
              United Kingdom
            </address>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
