import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';


function NavBar() {
  return (
    <nav>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li>
            <Link to="/team">Meet the Team</Link>
        </li>
        <li>
            <Link to="/contact">Contact Us</Link>
        </li>
        <li>
            <Link to="/careers">Careers</Link>
        </li>
        <li>
          <div className="dropdown">
            <Link to="#" className="dropbtn"><FontAwesomeIcon icon={faPlay} className="nav-arrow" />News</Link>
            <div className="dropdown-content">
              <Link to="/blogs">Blogs</Link>
              <Link to="/videos">Videos</Link>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
