import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './NavBar';
import MobileNavBar from './MobileNavBar';
import Footer from './Footer';
import Landing from './Landing';
import MeetTheTeam from './MeetTheTeam';
import Careers from './Careers';
import Contact from './Contact';
import Blogs from './Blogs';
import Videos from './Videos';
import './App.css';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 850);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
              <div className="App">
                {isMobile ? <MobileNavBar /> : <NavBar />}
                <div className="content">
                  <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/team" element={<MeetTheTeam />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/videos" element={<Videos />} />
                  </Routes>
                </div>
                <Footer />
              </div>
    </Router>
  );
}

export default App;
