import React from 'react';
import './App.css';
import meethteteam1 from './assets/images/meettheteam1.png';
import meethteteam2 from './assets/images/meettheteam2.jpg';
import meethteteam3 from './assets/images/meettheteam3.jpg';

const MeetTheTeam = () => {
    return (
        <div className="meet-the-team-container">
            <h1>Meet the Team</h1>
            <p>We have a very knowledgeable, friendly team that can help and advise you on what is best for your needs. We work with all the major seed breeders in the UK to offer you the best options for your farm.</p>
            <div className="team-upper-image">
                <img src={meethteteam1} alt="Meet the Team" />
            </div>
            <p>
                Here in Eye Suffolk we hold a large amount of products packed and ready to go, so are able to respond to your needs with our fast efficient delivery service, wherever possible on a next day basis.
                &nbsp;Our purchasing and production teams work very hard on keeping costs to a minimum so we are always able to be competitively priced and quality driven offering you the best possible outcome.
            </p>
            <div className="team-lower-images">
                <img src={meethteteam2} alt="Team Member 2" />
                <img src={meethteteam3} alt="Team Member 3" />
            </div>
        </div>
    );
};

export default MeetTheTeam;
