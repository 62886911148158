import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function MobileNavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="mobile-nav">
      <div className="nav-header">
        <div className="hamburger" onClick={toggleMenu}>
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </div>
      </div>
      <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li className="mobile-nav-link"><Link to="/" onClick={closeMenu}>Home</Link></li>
          <li className="mobile-nav-group"><Link to="/team" onClick={closeMenu}>Meet the Team</Link></li>
          <li className="mobile-nav-group"><Link to="/careers" onClick={closeMenu}>Careers</Link></li>
          <li className="mobile-nav-group"><Link to="/contact" onClick={closeMenu}>Contact Us</Link></li>
          <li className="mobile-nav-group"><Link to="/blogs" onClick={closeMenu}>Blogs</Link></li>
          <li className="mobile-nav-group"><Link to="/videos" onClick={closeMenu}>Videos</Link></li>

        </ul>
      </div>
    </nav>
  );
}

export default MobileNavBar;
